.btn {
  font-family: "SFreg" !important;
  padding-top: 3px !important;
  border: none;
  padding-bottom: 3px !important;
  border-radius: 9px;
  color: #ffffff;
}
.navbar {
  border-bottom: 1px solid #222222;
  background-color: #000000;
}
.login-btn,
.brand {
  opacity: 0.6;
  transition: all 0.38s;
}
.login-btn:hover,
.brand {
  opacity: 1;
}
.profile-btn img {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  margin-right: 5px;
  border: 1px solid rgb(94, 94, 94);
}
.profile-btn i {
  margin-left: 5px;
}
.profile-btn {
  color: white;
  font-family: "SFReg";
  opacity: 0.8;
  transition: all 0.38s;
}
.profile-btn:hover {
  opacity: 1;
}

a {
  text-decoration: none;
}
.brand {
  color: #ffffff;
  opacity: 0.36;
}
.brand:hover {
  opacity: 1;
}

.search-cont {
  background-color: #000000;
  padding: 20px;
  height: fit-content;
}
.search {
  display: flex;
  font-family: "SFReg";
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  transition: all 0.38s;
  opacity: 0.7;
  max-width: 480px;
  border-bottom: 1px solid #3d3d3d;
}
.search:focus-within {
  opacity: 1;
  border-bottom: 1px solid #2185d5;
}
.search input {
  width: 100%;
  color: rgb(187, 187, 187);
  cursor: pointer;
  border: 0px;
}
.search button img {
  padding-bottom: 3px;
  width: 18px;
}
.search button {
  border-radius: 0 30px 30px 0;

  backdrop-filter: blur(20px);
  background-color: none;
  width: fit-content;
  border: none;
  color: #4e4e4e;
}
.shop-btn {
  width: 100% !important;
  background-color: #740000;
  color: white;
}
.shop-btn:hover {
  opacity: 0.9;
  width: 100% !important;
  background-color: #2193d5;
  color: white;
}
.card-d {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card {
  width: 20rem;
  border: none;
  margin: 10px;
  border-radius: 20px;
  height: fit-content;
  transition: all 0.6s;
}
.card:hover {
  -webkit-box-shadow: -1px 0px 42px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 42px -18px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 42px -18px rgba(0, 0, 0, 0.75);
}
.card-img {
  border-radius: 20px;
  width: 100%;
}
.p-a {
  display: flex;
}
.card-b {
  position: absolute;
  top: 70%;
  background-color: #000000b2;
  backdrop-filter: blur(20px);
  width: 100%;
  height: 69px;
  padding: 15px;
  border-radius: 0 0 20px 20px;
  color: white;
}
.card-sk {
  position: absolute;
  top: 70%;
  background-color: #000000b2;
  backdrop-filter: blur(20px);
  width: 100%;
  height: 200px;
  border-radius: 0 0 20px 20px;
  color: white;
}
.card-d {
  display: none;
}
.card-t {
  width: 100%;
  font-size: 20px;
}
.card:hover .card-t {
  display: none;
  animation: fadein 0.3s;
}

.del-card {
  background-color: #c50000;
  width: fit-content;
  position: absolute;
  font-size: 12px;
  padding: 1px 6px;
  top: 27%;

  right: 10%;
  color: #ececec;
  border-radius: 30px;
}
.card:hover .card-d {
  overflow: hidden;
  font-size: 20px;
  width: 179px;
  animation-name: fadeout;
  animation-duration: 0.3s;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.card i {
  text-align: end;
}

.shop-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}
.search input,
button {
  border: none;
  background: none;
}

input:focus {
  outline: none;
}
input:focus button {
  color: rgb(114, 16, 16);
}
.c {
  margin: 20px;
}
.c-img {
  width: 100%;
  height: 480px;
}

.ad-card {
  border-radius: 20px;
  margin-bottom: 60px;
}
.ad-card img {
  max-width: 400px;
  height: 400px;
  border-radius: 20px;
}
.shop-det {
  height: 100px;
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
}
.shop-det-c {
  display: flex;
  width: 100%;
}
.shop-det-c button {
  margin-left: auto;
}
.p-btn {
  color: rgb(206, 206, 206);
}

.shop-card {
  background-color: #0000009d;
  color: #ffffff;
  width: 100%;
  text-align: center;
  backdrop-filter: blur(20px) saturate(200%);
  width: fit-content;
  padding: 30px;
  /* margin: 20px; */
  border-radius: 20px;

  margin-left: auto;
  margin-right: auto;
}

.shop-container {
  background-color: #000000;
  position: absolute;
  width: 100%;
  z-index: 2;
}
img.background {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;

  height: 100%;
  -webkit-filter: blur(80px) saturate(180%); /* Safari 6.0 - 9.0 */
  filter: blur(80px) saturate(180%);
}
.shop-container {
  padding: 20px;
}
.shop-owner {
  color: #ffffff !important;
  background: #000;
  padding: 10px 20px;
  border-radius: 30px;
}
.shop-details h2 {
  font-family: "SFBold";
  margin: 0;
}
.shop-desc {
  color: #eeeeee;
  text-align: center;
  background-color: #000000a9;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  backdrop-filter: blur(20px) saturate(180%);
}
.gallery img {
  border-radius: 20px;
  outline: none;
}

.shop-desc h3 {
  font-family: "SFBold";
}
.shop-desc {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.gallery {
  max-width: 400px;
}

.acc-btn button {
  text-transform: capitalize;
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-top: 8px;
  width: 100%;
  max-width: 100%;

  color: white;
  border-radius: 30px;
}
.phone {
  background-color: none;
  color: white;
}
.email {
  background-color: white;
  color: #000 !important;
}
.whatsapp {
  background: #25d366;
}
.whatsapp_grp {
  background: #128c7e;
}
.loaction {
  background: #4285f4;
}
.twitter {
  background: #00acee;
}
.telegram_grp {
  background: #0088cc;
}
.youtube {
  background: #cd201f;
}
.facebook {
  background: #1778f2;
}
.instagram {
  background: #d6249f;
  background: radial-gradient(
    circle at -10% 150%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}
.threads {
  background: #000;
}
.profile {
  padding-top: 20px;
  color: #ffffff;
}
.p-cont {
  background-color: #000000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.circle-num {
  background-color: white;
  padding: 1px 5px;
  color: #000000;
  font-size: 12px;
  border-radius: 30px;
}
.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-details img {
  border-radius: 100px;

  margin-right: 10px;
}
.user-details h3 {
  font-family: "SFBold";
  margin: 0;
}
.user-details h5 {
  color: #b4b4b4b0;
}
.user-buttons {
  text-align: center;
  display: flex;
  background-color: #20202080;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 30px;
  border-radius: 20px;
}
.add-btn {
  background-color: white;
  padding: 5px 20px;
  margin-left: 10px;

  border-radius: 20px;
}
.add-btn:hover {
  opacity: 0.8;
}
.post-line {
  text-align: center;
  cursor: pointer;
}
.post-line h6 {
  border-bottom: 1px solid rgb(51, 51, 51);
  margin: 0;
  padding: 10px;
  text-align: center;
}
.add-form {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #000;
  border-radius: 30px;
}
.add-form input,
.add-form button {
  display: block;
  width: 100%;
  margin-top: 10px;
  background: none;
  padding: 10px 20px;
  border: none;
  border-bottom: 1px solid rgb(211, 211, 211);
}
.add-form input:focus {
  border-bottom: 1px solid #0165e1;
}
.add-form button {
  border: none;
  background: #0165e1;
  color: white;
  font-size: 18px;
  border-radius: 16px;
}
.add-form button:disabled {
  opacity: 0.5;
}
.add-form input[type="file"] {
  background-color: #f5f5f5;
  border: none;
  color: rgb(44, 44, 44);
  border-radius: 20px;
}
label {
  color: rgb(44, 44, 44);
  padding: 1px 20px;
}
.add-form ::-webkit-file-upload-button {
  background-color: #000000;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
}
.basic-details {
  background-color: #ffffff;
  padding: 10px;
}
.preview {
  max-width: 400px;
  margin-top: 10px;
}
.preview img {
  border-radius: 30px;
  border: #00cc00;
}

.remove-btn:hover {
  filter: opacity(0.8);
}
.edit-btn {
  position: absolute;
  z-index: 2;
  transform: translate(0%, 25%);
  background-color: rgba(0, 0, 0, 0.568);
  padding: 5px 20px;
  margin-left: 10px;
  backdrop-filter: blur(20px) saturate(180%);
  border-radius: 20px 0 20px 0;
}
.edit-btn button {
  color: white;
}
.input-row {
  background-color: #ececec;
  padding: 15px;
  margin: 10px;
  border-radius: 30px;
}
.input-row input,
.input-row input:focus {
  border: none;
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
}
.add-input-btn {
  display: flex;
}
.add-input-btn button {
  margin: 7px;
}
.gallery {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.add-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;

  backdrop-filter: blur(20px) saturate(180%);
  padding: 10px;
  text-align: center;
  width: fit-content;
  border-radius: 30px;
}
.link-added {
  color: rgb(22, 192, 0) !important;
}
.add-link button {
  background: rgb(243, 243, 243);
  width: fit-content;
  color: black;
  padding: 10px 20px;
  font-size: 18px;
  margin: 10px;
  border-radius: 20px;
}
.add-link-btn {
  background-color: #00cc00 !important;
}
.no-alert,
.alert-c {
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 250px;
  min-width: 100%;
}
.no-alert {
  background-color: #ebebeb;
  user-select: none;
  color: grey;
  border-radius: 10px;
}
.alert-c {
  background-color: rgb(247, 247, 247);
  color: rgb(255, 30, 0);
  border-radius: 30px;
}
.message {
  background-color: rgb(247, 247, 247);
  color: rgb(58, 58, 58);
  border-radius: 30px;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  min-width: 100%;
}
footer {
  color: #979797;
  font-family: "SFReg";
  background-color: #000;
  margin: 0;
  padding: 20px;
}

/*/**/ /**/ /***/
.loader-c {
  color: #ffffff;
}
.loader-line {
  width: 100%;
  height: 1px;
  position: relative;
  overflow: hidden;
  background-color: #000000;

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 1px;

  width: 40%;
  background-color: rgb(165, 165, 165);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.ecard {
  background-color: #000;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding-top: 50px;
}
.whitebg {
  background-color: #ffffff;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding-top: 50px;
}
.ecard-msg {
  display: flex;
  background: #363636b2;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 20px;
}
.ad img {
  border-radius: 30px;
}