@font-face {
  font-family: 'SFreg';
  src: url('./fonts/SFPRODISPLAYREGULAR.OTF');
}
@font-face {
  font-family: 'SFbold';
  src: url('./fonts/SFPRODISPLAYBOLD.OTF');
}
body {
  margin: 0;
  font-family: 'SFreg',Mija,-apple-system,Arial,BlinkMacSystemFont,roboto slab,droid serif,segoe ui,Ubuntu,Cantarell,Georgia,serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: white;
}

.home{
  min-height: 100vh;
 
}